import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const object = [{
    type: 'select',
    name: t("inface.text3"),
    prop: 'tenantId',
    isPostApi: 'shopList',
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'input',
    name: t("adminDict.name2"),
    prop: 'dictType',
},{
    type: 'select',
    name: t("adminDict.name4"),
    prop: 'systemFlag',
    isParms: parmsArray[1]
}]