<template>
    <div class="execution">
        <div class="serchView">
            <fromSearch :object_='object_' @searchFun='getList' @changeParm='changeParm'/>
        </div>
        <avue-crud ref="crud" :page="page" :data="page.list"
            :table-loading="listLoading"
            :option="tableOption1"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <div class="meunButton">
                    <el-button class="filter-item" type="primary" 
                        @click="openView(0)" >{{$t("button.add")}} </el-button>
                    <el-button v-if="sys_dict_del"
                        class="filter-item" type="primary"
                        @click="handleRefreshCache" >{{$t("button.cache")}} </el-button>
                </div>
                
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #systemFlag="scope">
                {{getDataAboutText(1,scope.row.systemFlag)}}
            </template>
            <template #caozuo="scope" >
                 <el-button v-if="sys_dict_add" text
                    type="primary" size="small"  @click="openView(1,scope.row)">{{$t("button.update")}}
                </el-button>
                 <el-button v-if="sys_dict_add" text
                    type="primary" size="small"  @click="rowDel(scope.row)">{{$t("button.del")}}
                </el-button>
                <el-button v-if="sys_dict_add" text
                    type="primary" size="small"  @click="openView(2,scope.row)">{{$t("button.dictionary")}}
                </el-button>
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <infoView ref='infoView_' v-if='isStyle === 2'/>
            <bindView v-else ref='bindView_' @successFun='successFun' @closeFun='dialogFormVisible = false'/>
            <template #footer  v-if='isStyle !== 2'>
                <el-button   @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"  @click="completeFun_">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
 
<script setup>
    import { ref,getCurrentInstance,nextTick } from 'vue'
    import { tableOption } from '@/const/crud/dict'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import infoView from './info.vue'
    import bindView from './bind.vue'
    import { object } from '@/const/from/admin/dict'
    import fromSearch from '@/components/fromSearch.vue'
    const object_ = ref(object);
    const { $t } = getCurrentInstance().proxy;
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataAboutText } from '@/utils/dataBind/parmsView'

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getDictList',['delDict','id']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun,changeParm} = pageObj
    
    const infoView_ = ref(null)
    const bindView_ = ref(null)
    const tableOption1 = ref(null);tableOption1.value = tableOption

    const sys_dict_del = ref(true)
    const sys_dict_add = ref(true)
    const isStyle = ref(0)
    const isStyleText = [$t("adminDict.text1"),$t("adminDict.text2"),$t("adminDict.text3")]
    
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        if(num === 2){
            nextTick(()=>{
                infoView_.value.init_(parm)
            })
        }else{
            nextTick(()=>{
                bindView_.value.init_(num,parm)
            })
        }
    }
    const completeFun_ = ()=>{
        bindView_.value.completeFun()
    }
    const handleRefreshCache = ()=>{
        Api_.refreshCache().then(()=>{ 
            ElNotification({
                message:$t("alert.succSync"),
                type: 'success',
            })
        })
    }
    getList(1)
</script>

